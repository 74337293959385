const GridItem = ({ image, title, author, handleClick }) => {
  return (
    <div className="grid-item" onClick={handleClick}>
      <div className="grid-item-hover">
        <p className="grid-item-hover--name">{title}</p>
        <p className="grid-item-hover--author">{author}</p>
      </div>
      <img src={image} alt='imagen' />
    </div>
  );
};

export default GridItem;
