import React from "react";

const LayerBox = ({ text = "", textColor = "white" }) => {
  return (
    <div className="layerBox">
      <div className="layerBox--topLeft"></div>
      <div className="layerBox--topRight"></div>
      <div className="layerBox--bottomLeft"></div>
      <div className="layerBox--bottomRight"></div>
      <p className={`title--36 title--36-bold col-${textColor}`}>
        {text.toUpperCase()}
      </p>
    </div>
  );
};

export default LayerBox;
