import React, { useState, useEffect } from "react";
import CardContact from "../components/CardContact";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const objJuanpa = {
  name: "Juan Pablo Ortiz",
  role_es: "SOCIO / PRODUCTOR EJECUTIVO INTERNACIONAL",
  role_en: "PARTNER / INTERNATIONAL EXECUTIVE PRODUCER",
  email: "jp.ortiz@shootquantico.com",
};
const objStephanie = {
  name: "Stephanie Stockli",
  role_es: "US HISPANIC REP.",
  role_en: "US HISPANIC REP.",
  email: "stephanie@igotcreative.com",
};
const objXimena = {
  name: "Ximena Lazo",
  role_en: "EXECUTIVE PRODUCER",
  role_es: "PRODUCTOR EJECUTIVO",
  email: "ximena@shootquantico.com",
};
const objLaura = {
  name: "Laura Sanchez",
  role_en: "EXECUTIVE PRODUCER",
  role_es: "PRODUCTOR EJECUTIVO",
  email: "lau@gloriuos.com.co",
};

const objIsabel = {
  name: "Isabel Echeverry ",
  role_en: "U.S. Hispanic Market",
  role_es: "U.S. Hispanic Market",
  email:"isabel@kontaktoreps.com"
}

const Contact = () => {
  const [languageSelected, setLanguageSelected] = useState("es");

  const initLanguage = () => {
    const languageStorage = localStorage.getItem("language");
    if (languageStorage) setLanguageSelected(JSON.parse(languageStorage));
  };

  useEffect(() => {
    initLanguage();
  }, []);

  const cardMiami = (
    <CardContact
      language={languageSelected}
      city="U.S."
      address="BRICKELL CENTRE - 78 SW 7TH ST, MIAMI, FL 33130"
      phone="+1 786 629 7476"
      contacts={[objJuanpa, objIsabel]}
    />
  );
 
  const cardLima = (
    <CardContact
      language={languageSelected}
      city="Perú"
      address="Av. Juan de Aliaga 425 - Of. 401, Magdalena del Mar"
      phone="+511 500 5806"
      contacts={[objJuanpa]}
    />
  );

  const cardBogota = (
    <CardContact
      language={languageSelected}
      city="Colombia"
      address="CRA 13 BIS #108 - 21 OF 204, BOGOTA"
      phone="+57 304 3891727"
      contacts={[]}
    />
  );

  const cardCDMX = (
    <CardContact
      language={languageSelected}
      city="Mexico"
      address="ORIZABA 184, COL. ROMA, CIUDAD DE MEXICO, 06700"
      phone="+52 55 6842 2209"
      contacts={[objJuanpa]}
    />
  );

  const SlideCard = ({ slidesNumber, isCenter }) => (
    <Swiper
      // spaceBetween={20}
      slidesPerView={slidesNumber}
      centeredSlides={isCenter}
      loop
      navigation
    >
      <SwiperSlide key={0}>
        <div className="slide-container">{cardMiami}</div>
      </SwiperSlide>
      <SwiperSlide key={1}>
        <div className="slide-container">{cardLima}</div>
      </SwiperSlide>
      <SwiperSlide key={2}>
        <div className="slide-container">{cardBogota}</div>
      </SwiperSlide>
      <SwiperSlide key={3}>
        <div className="slide-container">{cardCDMX}</div>
      </SwiperSlide>
    </Swiper>
  );

  return (
    <div className="contact">
      <div className="contact__wrapper--desktop-large">
        {cardMiami}
        {cardLima}
        {cardBogota}
        {cardCDMX}
      </div>
      <div className="contact__wrapper--desktop">
        <SlideCard slidesNumber={3} isCenter={false} />
      </div>
      <div className="contact__wrapper--desktop-mini">
        <SlideCard slidesNumber={2} isCenter={false} />
      </div>
      <div className="contact__wrapper--tablet">
        <SlideCard slidesNumber={1} isCenter={true} />
      </div>
    </div>
  );
};

export default Contact;
