import React, { useState, useEffect } from "react";
import dataJson from "../utils/translate.json";
import { IconContext } from "react-icons";
import iconScrollDown from '../assets/icon-scroll-down.svg';

const Colab = () => {
  const [languageSelected, setLanguageSelected] = useState("es");

  const initLanguage = () => {
    const languageStorage = localStorage.getItem("language");
    if (languageStorage) setLanguageSelected(JSON.parse(languageStorage));
  };

  useEffect(() => {
    initLanguage();
  }, []);

  const goToBottom = () => {
    const $bottom = document.querySelector(".colab--bottom");
    $bottom.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="colab">
      <div className="colab--top">
        <p className="title--72">CO-Lab</p>
        <p className="title--36 title--36-bold">
          {dataJson[languageSelected].colab.split("&&&")[0]}
          <br />
          <br />
          {dataJson[languageSelected].colab.split("&&&")[1]}
        </p>
      </div>
      <IconContext.Provider value={{ color: "white" }}>
        <button className="colab--scroll-bottom" onClick={goToBottom}>
          <img src={iconScrollDown} alt=""/>
        </button>
      </IconContext.Provider>
      <div className="colab--bottom">
        <div className="colab--bottom--img1" />
        <div className="colab--bottom--img2" />
        <div className="colab--bottom--img3" />
        <div className="colab--bottom--img4" />
      </div>
    </div>
  );
};

export default Colab;
