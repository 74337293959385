import { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Player from "@vimeo/player";
import { IconContext } from "react-icons";
import { IoMdPlay } from "react-icons/io";
import iconScrollDown from '../assets/icon-scroll-down.svg';

const PSBanner = ({ setVideoOnMobile }) => {
  const ref = useRef(null);
  const controlRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [controlPanel, setControlPanel] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.offsetWidth);
    };
    setWidth(ref.current.offsetWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);
  const handleReady = () => {
    setControlPanel(true);
    const iframe = document.querySelector("#ps-banner iframe");
    const player = new Player(iframe);
    controlRef.current.addEventListener("click", () => {
      player.getPaused().then(function (paused) {
        if (paused) {
          player.play();
        } else {
          player.pause();
        }
      });
    });
  };
  const goToInfo = () => {
    const $info = document.querySelector("#ps-info");
    $info.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="grid-item" id="ps-banner" ref={ref}>
      <div
        className={`grid-item-controls ${controlPanel ? "show" : ""}`}
        ref={controlRef}
      />
      {width > 690 ? (
        <ReactPlayer
          url="https://player.vimeo.com/video/256965529"
          playsinline
          light="https://d3e0ws1qnk7y4e.cloudfront.net/ps-banner.png"
          playing
          width="100%"
          height={width * 0.5625}
          onReady={handleReady}
        />
      ) : (
        <div className="grid-item-image">
          <IconContext.Provider value={{ color: "white" }}>
            <IoMdPlay />
          </IconContext.Provider>
          <img
            src="https://d3e0ws1qnk7y4e.cloudfront.net/ps-banner.png"
            alt="slide"
            onClick={setVideoOnMobile}
          />
        </div>
      )}
      <IconContext.Provider value={{ color: "white" }}>
        <button className="grid-item-down" onClick={goToInfo}>
          <img src={iconScrollDown} alt=""/>
        </button>
      </IconContext.Provider>
    </div>
  );
};

export default PSBanner;
