import React from "react";
import PropTypes from "prop-types";
import LayerBox from "./LayerBox";

const CardContact = ({ language, city, address, phone, contacts, props }) => {
  return (
    <div className="cardContact" {...props}>
      <LayerBox text={city} textColor="white" />
      <p className="title--24 title--24-bold">{address}</p>
      <p className="title--24 title--24-bold">{phone}</p>
      <div className="cardContact__list">
        {contacts.map((contact) => (
          <div className="cardContact__item">
            <p className="title--24 title--24-bold">{contact.name}</p>
            <p className="title--16 title--16-bold">
              {contact[`role_${language}`]}
            </p>
            <p className="title--16 title--16-regular">{contact.email}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

CardContact.propTypes = {
  props: PropTypes.any,
  language: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  contacts: PropTypes.array,
};
CardContact.defaultProps = {
  language: "es",
  city: null,
  address: "",
  phone: "",
  contacts: [],
};

export default CardContact;
