import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Header } from "./components";
import appRoutes from "./routes";
import { HOME_PATH } from "./utils";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        {appRoutes.map((route) => (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route key={0} path="*">
          <Redirect to={HOME_PATH} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
