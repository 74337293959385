import ReactPlayer from 'react-player'
import { IconContext } from "react-icons"
import { MdClose } from "react-icons/md"


const PopupPlayer = ({ video, handleClose }) => {
  return (
    <div className="grid-popup">
      <div className="grid-popup-container">
        <ReactPlayer
          url={video}
          playing
          width="100%"
          height="100%"
          controls
        />
      </div>
      <IconContext.Provider value={{ color: "white" }}>
        <button onClick={handleClose}>
          <MdClose />
        </button>
      </IconContext.Provider>
    </div>
  );
};

export default PopupPlayer;
