import React from "react";
import ExecutiveGrid from "../components/ExecutiveGrid";

const Executive = () => {
  const [country, setCountry] = React.useState("");
  React.useEffect(() => {
    const fetchInfo = async () => {
      const res = await fetch("http://ip-api.com/json");
      const resJson = await res.json();
      if (resJson.status === "success") {
        console.log(
          "🚀 ~ file: Executive.jsx ~ line 12 ~ fetchInfo ~ resJson.country",
          resJson.country
        );
        setCountry(resJson.country);
      }
    }; 
    fetchInfo();
  }, []);
  return <ExecutiveGrid country={country} />;
};

export default Executive;
