import Logo from "../assets/Forma 1.png";
import LogoGreen from "../assets/Forma 234.png"
import Menu from "../assets/Grupo 2.png";
import MenuGreen from "../assets/Grupo4.png";
import { useLocation, useHistory } from "react-router-dom";


const Header = () => {
  const history = useHistory();
  const { pathname } = useLocation()
  const openMenu = () => {
    history.push('/menu');
  }
  const goToHome = () => {
    history.push('/')
  }
  return (
    <header className="header">
      <div className="header-container">
        <img src={pathname !== '/directores' ? Logo : LogoGreen} alt="quantico" className="logo" onClick={goToHome} />
        <button className="menu" onClick={openMenu}>
          <img src={pathname !== '/directores' ? Menu : MenuGreen} alt="menu" />
        </button>
      </div>
    </header>
  );
};

export default Header;
