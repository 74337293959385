import {
  Colab,
  Contact,
  Executive,
  Home,
  Manifest,
  Menu,
  ProductionService,
} from "../layouts";
import { HOME_PATH } from "../utils";

let appRoutes = [
  {
    id: 1,
    exact: false,
    path: "/colab",
    name: "Colab",
    component: Colab,
  },
  {
    id: 2,
    exact: false,
    path: "/contacto",
    name: "Contacto",
    component: Contact,
  },
  {
    id: 3,
    exact: false,
    path: "/directores",
    name: "Directores",
    component: Executive,
  },
  { id: 4, exact: true, path: HOME_PATH, name: "Home", component: Home },
  {
    id: 5,
    exact: false,
    path: "/manifiesto",
    name: "Manifiesto",
    component: Manifest,
  },
  {
    id: 6,
    exact: false,
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    id: 7,
    exact: false,
    path: "/offer",
    name: "Ofrecemos",
    component: ProductionService,
  },
];

export default appRoutes;
