import { useState, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import {
  BsChevronCompactLeft,
  BsChevronCompactRight,
} from "react-icons/bs";
import SliderItem from "../components/SliderItem";
import GridItem from "../components/GridItem";
import PopupPlayer from "../components/PopupPlayer";
import iconScrollDown from '../assets/icon-scroll-down.svg';
 
const HomeGrid = () => {
  const interval = useRef(null)
  const [selected, setSelected] = useState(0);
  const [itemSlider, setItemSlider] = useState(0);
  const [itemGrid, setItemGrid] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const sliderVideos = [
   // "https://vimeo.com/677905026",
   // "https://vimeo.com/677899880",
   // "https://vimeo.com/571894387",
   "https://vimeo.com/719687641",
   "https://vimeo.com/737718438",
   "https://vimeo.com/731528173",
   "https://vimeo.com/734047632",
    //"https://vimeo.com/374985458"
  ];
  const gridVideos = [
    "https://vimeo.com/734045851",
    "https://vimeo.com/723595182",
    "https://vimeo.com/734043283",
    "https://vimeo.com/734047632",
    "https://vimeo.com/677905026",
    "https://vimeo.com/390092712",
    "https://vimeo.com/274180054",
  ];
  const goToGridVideos = () => {
    const $grid = document.querySelector("#grid-videos");
    $grid.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    interval.current = setInterval(() => {
      setSelected((prev) => (prev < 4 ? prev + 1 : 0))
    }, 10000);
    return () => {
      clearInterval(interval.current)
      interval.current = null
    }
  }, []);
  useEffect(() => {
    if (!isPlaying) {
      clearInterval(interval.current);
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          setSelected((prev) => (prev < 4 ? prev + 1 : 0))
        }, 10000);
      }
    }
    return () => {
      clearInterval(interval.current)
      interval.current = null
    }
  }, [isPlaying]);
  return (
    <div className="grid-root">
      <div className="grid-container">
        <div className={`grid-slider ${isPlaying ? "playing" : ""}`}>
          <IconContext.Provider value={{ color: "white" }}>
            <button
              className="grid-slider-prev"
              onClick={() =>
                setSelected((prev) => (prev > 0 ? prev - 1 : prev))
              }
            >
              <BsChevronCompactLeft />
            </button>
            <button
              className="grid-slider-next"
              onClick={() =>
                setSelected((prev) => (prev < 3 ? prev + 1 : prev))
              }
            >
              <BsChevronCompactRight />
            </button>
          </IconContext.Provider>
          <div className={`grid-slides grid-selected-${selected}`}>
            <SliderItem
                image="https://d3e0ws1qnk7y4e.cloudfront.net/SAP Hilo Azul.jpg"
                video="https://vimeo.com/737718438"
                isSelected={selected === 0}
                idIframe="slider-2"
                setVideoOnMobile={() => setItemSlider(2)}
                author="Miguel Vila"
                name="SAP - Hilo Azul"
                playingg= {isPlaying}
                setIsPlaying={setIsPlaying}
              />   
            <SliderItem
              image="https://d3e0ws1qnk7y4e.cloudfront.net/BCP Tatuaje.jpg"
              video="https://vimeo.com/734047632"
              isSelected={selected === 1}
              idIframe="slider-2"
              setVideoOnMobile={() => setItemSlider(4)}
              author="Daniel + Rafo"
              name="BCP - Cuenta Millonaria - Tatuaje"
              playingg= {isPlaying}
              setIsPlaying={setIsPlaying}
            />                                    
            <SliderItem
              image="https://d3e0ws1qnk7y4e.cloudfront.net/UTEC.jpg"
              video="https://vimeo.com/731528173"
              isSelected={selected === 2}
              idIframe="slider-1"
              setVideoOnMobile={() => setItemSlider(3)}
              author="Juan Diego Servat"
              name="UTEC - Metaverso UTEC" 
              playingg= {isPlaying}
              setIsPlaying={setIsPlaying}
            /> 
              <SliderItem
                image="https://d3e0ws1qnk7y4e.cloudfront.net/Changan.jpg"
                video="https://vimeo.com/719687641"
                isSelected={selected === 3}
                idIframe="slider-1"
                setVideoOnMobile={() => setItemSlider(1)}
                author="Josefina Pieres"
                name="Changan Uni-T 2022" 
                playingg= {isPlaying}
                setIsPlaying={setIsPlaying}
              />
          </div>
          <div className="grid-slider-dots">
            <div
              className={`grid-slider-dot ${selected === 0 ? "active" : ""}`}
              onClick={() => setSelected(0)}
            ></div>
            <div
              className={`grid-slider-dot ${selected === 1 ? "active" : ""}`}
              onClick={() => setSelected(1)}
            ></div>
            <div
              className={`grid-slider-dot ${selected === 2 ? "active" : ""}`}
              onClick={() => setSelected(2)}
            ></div>
            <div
              className={`grid-slider-dot ${selected === 3 ? "active" : ""}`}
              onClick={() => setSelected(3)}
            ></div>            
            {/* <div
              className={`grid-slider-dot ${selected === 3 ? "active" : ""}`}
              onClick={() => setSelected(3)}
            ></div> */}
          </div>
          <IconContext.Provider value={{ color: "white" }}>
            <button className="grid-slider-down" onClick={goToGridVideos}>
              <img src={iconScrollDown} alt=""/>
            </button>
          </IconContext.Provider>
        </div>
      </div>
      <div className="grid-container gap" id="grid-videos">
        
        <GridItem
          image="https://d3e0ws1qnk7y4e.cloudfront.net/BCP Rapada.jpg"
          title="BCP - Cuenta Millonaria - Rapada"
          author="Daniel + Rafo"
          handleClick={() => setItemGrid(1)}
        />
        <GridItem
          image="https://d3e0ws1qnk7y4e.cloudfront.net/Interbank - vas a retirar tu AFP.jpg"
          title="Interbank - Vas a Retirar tu AFP"
          author="Daniel Martin Rodriguez"
          handleClick={() => setItemGrid(2)}
        />
        <GridItem
          image="https://d3e0ws1qnk7y4e.cloudfront.net/daniel%20%2B%20rafo%20-%20geely.jpg"
          title="Geely - Tu Geely Sabe"
          author="Daniel + Rafo"
          handleClick={() => setItemGrid(2)}
        />        
        <GridItem
          image="https://d3e0ws1qnk7y4e.cloudfront.net/Caja Piura.JPG"
          title="Caja Piura - 40 Aniversario"
          author="Jeff Carrera"
          handleClick={() => setItemGrid(5)}
        />
        <GridItem
          image="https://d3e0ws1qnk7y4e.cloudfront.net/Ford Escape 2020.jpg"
          title="Ford - Ford Escape 2020"
          author="Rafa Carvalho"
          handleClick={() => setItemGrid(6)}
        />
        <GridItem
          image="https://d3e0ws1qnk7y4e.cloudfront.net/PEPSI+Modo+Futbol.JPG"
          title="Pepsi - #ModoFutbol"
          author="Diego Fried"
          handleClick={() => setItemGrid(7)}
        />        
      </div>
      {itemGrid > 0 && (
        <PopupPlayer
          video={gridVideos[itemGrid - 1]}
          handleClose={() => setItemGrid(0)}
        />
      )}
      {itemSlider > 0 && (
        <PopupPlayer
          video={sliderVideos[itemSlider - 1]}
          handleClose={() => setItemSlider(0)}
        />
      )}
    </div>
  );
};

export default HomeGrid;
