import { useEffect, useState } from "react";
import PopupPlayer from "../components/PopupPlayer";
import { executives, videosPerExecutive } from "../utils";
import LayerBox from "./LayerBox";

const ExecutiveGrid = ({ country }) => {
  const [selected, setSelected] = useState(0);
  const [executivesList, setExecutivesList] = useState([]);
  const [videoSelected, setVideoSelected] = useState(0);
  const [city, setCity] = useState("");
  const [languageSelected, setLanguageSelected] = useState("es");

  const init = () => {
    let city = localStorage.getItem("city")
      ? JSON.parse(localStorage.getItem("city"))
      : "lima";
    let list = executives[city.toUpperCase()].sort((a, b) =>
      a.localeCompare(b)
    );
    let director = parseInt(
      localStorage.getItem("director")
        ? JSON.parse(localStorage.getItem("director"))
        : "0"
    );
    const languageStorage = localStorage.getItem("language");
    if (languageStorage) setLanguageSelected(JSON.parse(languageStorage));
    setCity(city);
    setSelected(director);
    setExecutivesList(list);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const alreadyVisitedStorage = Boolean(localStorage.getItem("alreadyVisited"))
    if (!alreadyVisitedStorage) {
      switch(country) {
        case 'Peru':
          setCity('lima');
          break;
        case 'Colombia':
          setCity('bogota');
          break;
        case 'Mexico':
          setCity('cdmx');
          break;
        case 'United States':
          setCity('miami');
          break;
        default:
          let city = localStorage.getItem("city")
          ? JSON.parse(localStorage.getItem("city"))
          : "lima";
          setCity(city);
      }
      localStorage.setItem("alreadyVisited", "true")
    }
  }, [country]);

  useEffect(() => {
    if (executivesList.length) {
    }
  }, [selected, executivesList]);

  const handleSelectExecutive = (index) => {
    setSelected(index);
    localStorage.setItem("director", JSON.stringify(index));
  };
 
  return (
    <div className="grid-ex-root">
      <div className="grid-main">
        {city === "lima" && <LayerBox text="Perú" />}
        {city === "bogota" && <LayerBox text="Colombia" />}
        {city === "cdmx" && <LayerBox text="Mexico" />}
        {city === "miami" && (
          <LayerBox
            // text={`${languageSelected === "en" ? "U.S." : "EE.UU"}`}
            text="U.S."
          
          />
        )}
      </div>
      <div className="grid-tabs">
        {executivesList.map((executive, index) => (
          <div
            key={index}
            className={`grid-tab ${selected === index ? "active" : ""}`}
            onClick={() => handleSelectExecutive(index)}
          >
            <p>{executive}</p>
          </div>
        ))}
      </div>
      <div className="grid-container">
        {videosPerExecutive[executivesList[selected]] &&
          videosPerExecutive[executivesList[selected]].map((video) => (
            <div
              className="grid-item"
              key={video.id}
              onClick={() => setVideoSelected(video.id)}
            >
              <div className="grid-item-hover">
                <p className="grid-item-hover--name">{video.name}</p>
              </div>
              <img
                src={video?.other ? video.image  : `https://d3e0ws1qnk7y4e.cloudfront.net/${video.image}`}
                alt="director thumbnail"
              />
            </div>
          ))}
      </div>
      {videoSelected > 0 && (
        <PopupPlayer
          video={
            videosPerExecutive[executivesList[selected]][videoSelected - 1]
              .video
          }
          handleClose={() => setVideoSelected(0)}
        />
      )}
    </div>
  );
};

export default ExecutiveGrid;
