import React, { useState, useEffect } from "react";
import dataJson from "../utils/translate.json";
import PSBanner from "../components/PSBanner";
import PopupPlayer from "../components/PopupPlayer"
import Logo1 from "../assets/logo1.png"
import Logo2 from "../assets/logo2.png"
import Logo3 from "../assets/logo3.png"
import Logo4 from "../assets/logo4.png"
import Logo5 from "../assets/logo5.png"
import Logo6 from "../assets/logo6.png"
import Logo7 from "../assets/logo7.png"

const ProductionService = () => {
  const [showOnMobile, setShowOnMobile] = useState(false)
  const [languageSelected, setLanguageSelected] = useState("es");

  const initLanguage = () => {
    const languageStorage = localStorage.getItem("language");
    if (languageStorage) setLanguageSelected(JSON.parse(languageStorage));
  };

  useEffect(() => {
    initLanguage();
  }, []);
  return (
    <div className="ps-root">
      <div className="ps-container">
        <PSBanner setVideoOnMobile={() => setShowOnMobile(true)} />
        <div className="ps-info" id="ps-info">
          <h1 className="ps-info--title">
            {dataJson[languageSelected].offer.split("&&&")[0]}
            {/* WHAT WE OFFER */}
          </h1>
          <div className="ps-info-right">
            <p className="ps-info--text">
              {dataJson[languageSelected].offer.split("&&&")[1]}
              {/* Our bespoke production services have engendered a diverse stream of loyal partners from all over the world. Vested in the creation of your project, we are here to guide you as to the benefits of shooting with union or non union talent and/or crew. Additionally, we make sure that casting, location scouting, insurance requirements, payroll, celebrity sourcing, and client services are customized to the specific needs of your project. */}
            </p>
            <p className="ps-info--text">
              {dataJson[languageSelected].offer.split("&&&")[2]}
              {/* With offices in CDMX, Bogota, Lima and Miami , we are able to
              execute shoots in diverse formats- from DSLR to virtual reality,
              digital, film, etc.- all across South America including Mexico,
              Miami, LA and the Caribbean. No job is too large or too small as
              vetted and professional solutions can be tailored to fit virtually
              any budget. Some commercial, web and music video cost estimates as
              well as library location proposals can be turned around in 24
              hours or less. */}
            </p>
            <div className="ps-info--logos">
              <img src={Logo1} alt="logo1" />
              <img src={Logo2} alt="logo2" />
              <img src={Logo3} alt="logo3" />
              <img src={Logo4} alt="logo4" />
              <img src={Logo5} alt="logo5" />
              <img src={Logo6} alt="logo6" />
              <img src={Logo7} alt="logo7" />
            </div>
          </div>
        </div>
      </div>
      {showOnMobile &&
        <PopupPlayer
          video='https://player.vimeo.com/video/256965529'
          handleClose={() => setShowOnMobile(false)}
        />
      }
    </div>
  );
};

export default ProductionService;
