export const HOME_PATH = "/";
 
export const executives = {
  LIMA: [
    //"Analucía Morgan",
  //  "Daniel + Rafo",
    "Diego Fried", 
    //"Jeff Carrera",
    "Josefina Pieres",
 //   "Miguel Vila",
    "Juan Diego Servat",
    "Jose Navarro",
    "Alvaro Stocker",
    "Ivan Vaccaro",
    "Rosa Maria Santisteban"
  //  "VP- Vero Sanchez",
   // "Line Producer - Loshua Flores - Guerra",
  //  "Melissa Zegarra",
   // "Augusto Miro Quesada",
  ],
  BOGOTA: [
    //'Analucía Morgan',
   // 'Daniel + Rafo',
    'Diego Fried',
    "Jose Navarro",
  //  'Jeff Carrera',
    "Juan Diego Servat",
    "Hector Orbegoso",
    "Ivan Vaccaro"
    //"Daniel Danon",
    //"Felipe Rueda",
   // "Salomon Simhon",
    //"Rafa Martínez"
  ],
  MIAMI: [
    // 'Martin Jalfen',
    'Josefina Pieres',
    'Marcus Perry',
    'Jedediah Thunell',
    'Mariana Zdravca',
    'Marcello Lima',
    'Michael Parenteau',
    'Chris Bristow',
    'Ben Tricklebank',
    "Rafaela Carvalho",
    "Sophia Banks",
    "Alvaro Stocker",
    "Ivan Vaccaro",
  ],
  CDMX: [
    'Michael Parenteau',
    'Jedediah Thunell',
    'Marcus Perry',
    'Mariana Zdravca',
   // 'Pollo Arce',
    'Matt Bieler',
    'Chris Bristow',
    "Hector Orbegoso"
  ],
};
 export const videosPerExecutive = {
  "Rosa Maria Santisteban": [
    { id: 1, name: 'OSIPTEL - Clonacel', video: 'https://vimeo.com/743296693', image: 'clonacel .jpg' },
    { id: 2, name: 'Changan - Diseño Interior', video: 'https://vimeo.com/742960840', image: 'changan+disenio+exterior.jpg' },
    { id: 3, name: 'Changan - Autoparking', video: 'https://vimeo.com/742954338', image: 'changan autoparking.jpg' },
    { id: 4, name: 'Quien dijo Detox? - Trailer', video: 'https://vimeo.com/669591466', image: 'quien dijo detox.jpg' },
  ],
  "Analucía Morgan": [
    { id: 1, name: 'Generación Bicentenario', video: 'https://vimeo.com/533247399', image: 'am-1.jpg' },
    { id: 2, name: 'El Grito Short Film - Tráiler', video: 'https://vimeo.com/497692875', image: 'am-2.jpg' },
    { id: 3, name: '“Que Los Cumplas Feliz” - Short film tráiler', video: 'https://vimeo.com/281959808', image: 'am-3.jpg' },
    { id: 4, name: 'Días Reales / Real Plaza ', video: 'https://vimeo.com/677902509', image: 'dias-reales.jpg' },
  ],
  "Daniel + Rafo": [
    { id: 1, name: 'Arma tu navidad con Field / Nestle', video: 'https://vimeo.com/650941305', image: 'daniel-rafo/arma-navidad-field.jpg' },
    { id: 2, name: 'La Rentabilidad / AFP Integra', video: 'https://vimeo.com/677899880', image: 'daniel-rafo/afp-integra.jpg' },
    { id: 3, name: 'AJ ZOMBIES! - Trailer Oficial', video: 'https://vimeo.com/592918174', image: 'daniel-rafo/aj-zoombies.jpg' },
    { id: 4, name: 'RETUKIRI TUKIRI- Cortometraje (Extracto)', video: 'https://vimeo.com/593058237', image: 'daniel-rafo/retukiki.jpg' },
    { id: 5, name: 'TAMBO - Te Salvamos de San Valentín', video: 'https://vimeo.com/389867993', image: 'daniel-rafo/tambo.jpg' },
    { id: 6, name: 'Huawei - Cases con estilo', video: 'https://vimeo.com/389870671', image: 'daniel-rafo/huawei.jpg' },
  ],
  "Miguel Vila": [
    { id: 1, name: 'Hit', video: 'https://vimeo.com/297226772', image: 'miguel-villa/Hit.jpg' },
    { id: 2, name: 'Pepsi Black', video: 'https://vimeo.com/253617117', image: 'miguel-villa/pepsi-black.jpg' },
    { id: 3, name: 'GATORADE FIERCE COLOMBIA', video: 'https://vimeo.com/302839269', image: 'miguel-villa/GATORADE-FIERCE-COLOMBIA.jpg' },
    { id: 4, name: 'Movistar Navidad 2021 El Salvador', video: 'https://vimeo.com/665726250', image: 'miguel-villa/movistar-navidad.jpg' },
    { id: 5, name: 'Colombiana Yatra', video: 'https://vimeo.com/334916331', image: 'miguel-villa/colombiana-yatra.jpg' },
    { id: 6, name: 'PEPSI WEB', video: 'https://vimeo.com/141222299', image: 'miguel-villa/pepsi-web.jpg' },
    { id: 7, name: 'Ringo Vitality', video: 'https://vimeo.com/678516108', image: 'miguel-villa/ringo.jpg' },
  ],
  "Josefina Pieres": [
    { id: 1, name: 'Changan - UNI-T', video: 'https://vimeo.com/718744339', image: 'Changan.jpg' },
    { id: 2, name: 'Cuánto - Manifiesto', video: 'https://vimeo.com/379493203', image: 'josefina-pieres/cuanto-manifiesto.jpg' },
    { id: 3, name: 'Juan Ingaramo - No necesito', video: 'https://vimeo.com/489911094', image: 'josefina-pieres/juan-ingaramo.jpg' },
    { id: 4, name: 'Salgo a bailar - Sonia Z', video: 'https://vimeo.com/365559987', image: 'josefina-pieres/sonia-z.jpg' },
    { id: 5, name: 'Garnier - Nutrí tu vida de color', video: 'https://vimeo.com/172235730', image: 'josefina-pieres/garnier.jpg' },
    { id: 6, name: 'Pampers Pants - Director’s cut', video: 'https://vimeo.com/369370690', image: 'josefina-pieres/pampers-pants.jpg' },
    { id: 7, name: 'Fin de Fiesta – Trailer', video: 'https://vimeo.com/328748261', image: 'josefina-pieres/fin-de-fiesta.jpg' },
    { id: 8, name: 'JUST, in every way - Director’s cut', video: 'https://vimeo.com/369366718', image: 'josefina-pieres/just.jpg' },
    { id: 9, name: 'Esta canalla canción - Patricia Barreto', video: 'https://vimeo.com/638845518', image: 'josefina-pieres/esta-canalla-cancion.jpg' },
  ],
  "Salomon Simhon": [
    { id: 1, name: 'Qué Lástima / Chocquibtown Ft Sech', video: 'https://vimeo.com/393456868', image: 'salomon-simhon/que-lastima.jpg' },
    { id: 2, name: 'Malayerba - Trailer ', video: 'https://vimeo.com/672430053', image: 'salomon-simhon/malayerba.jpg' },
    { id: 3, name: 'Dj Khaled & Sech Borracho (directors cut)', video: 'https://vimeo.com/650400838', image: 'salomon-simhon/dj.jpg' },
    { id: 4, name: 'DIMELO FLOW - EL FAVOR - Ft Nicky Jam, Farruko, Zion, Sech, Lunay', video: 'https://vimeo.com/357207026', image: 'salomon-simhon/dimelo-flow.jpg' },
    { id: 5, name: 'Discover Extra Like (directors cut)', video: 'https://vimeo.com/347544360', image: 'salomon-simhon/discover-extra-like.jpg' },
    { id: 6, name: 'Mc Davo LOS BANDIDOS', video: 'https://vimeo.com/278696342', image: 'salomon-simhon/mc-davo.jpg' },
    { id: 7, name: 'BWIN Hablamos en la cancha', video: 'https://vimeo.com/572750230', image: 'salomon-simhon/bwin.jpg' },
    { id: 8, name: 'Free Florida Tobacco - Skip', video: 'https://vimeo.com/180043490', image: 'SKIP.JPG' },
    { id: 9, name: 'BWIN - Hablamos en la cancha', video: 'https://vimeo.com/572750230', image: 'Grissly.JPG' }
  ],
  "Daniel Danon": [
    { id: 1, name: 'Fútbol Calle', video: 'https://vimeo.com/154611663', image: 'daniel-danon/futbol-calle.jpg' },
    { id: 2, name: 'La Ejecución De Luis XVI 2', video: 'https://vimeo.com/59488182', image: 'daniel-danon/la-ejecucion.jpg' },
    { id: 3, name: 'Colcafé', video: 'https://vimeo.com/119252776', image: 'daniel-danon/colcafe.jpg' },
    { id: 4, name: 'Riaño Navidad', video: 'https://vimeo.com/302337816', image: 'daniel-danon/riano-navidad.jpg' },
    { id: 5, name: 'Juanpis Gonzáles', video: 'https://vimeo.com/306225450', image: 'daniel-danon/juanpis-gonzales.jpg' },
    { id: 6, name: 'Maturana', video: 'https://vimeo.com/149938609', image: 'daniel-danon/maturana.jpg' },
    { id: 7, name: 'Donantes', video: 'https://vimeo.com/151300152', image: 'Donantes+Espera.JPG' },
    { id: 8, name: 'Upper Side', video: 'https://vimeo.com/161654409', image: 'Upper+Side.JPG' },
  ],
  "Jedediah Thunell": [
    { id: 1, name: 'AVANA', video: 'https://vimeo.com/314180997', image: 'jeddeiah-thunell/avana.jpg' },
    { id: 2, name: 'Blenderbottle - The Call', video: 'https://vimeo.com/265932415', image: 'jeddeiah-thunell/the-call.jpg' },
    { id: 3, name: 'Land Rover: Defender - Legacy Reborn', video: 'https://vimeo.com/538992571', image: 'Defender+-+LEGACY+REBORN.JPG' },
    { id: 4, name: 'Hallmark - “What’s For Dinner?”', video: 'https://vimeo.com/396763124', image: 'jeddeiah-thunell/hallmark.jpg' },
    { id: 5, name: 'General Electric - One More Giant Leap', video: 'https://vimeo.com/297806706', image: 'jeddeiah-thunell/ge.jpg' },
    { id: 6, name: 'Lysol - “Here”', video: 'https://vimeo.com/396296468', image: 'jeddeiah-thunell/lysol.jpg' },
    { id: 7, name: 'the carpenter', video: 'https://vimeo.com/235583318', image: 'jeddeiah-thunell/the-carpenter.jpg' },
    { id: 8, name: 'Defender - Legacy Reborn', video: 'https://vimeo.com/538992571', image: 'jeddeiah-thunell/defender.jpg' },
    { id: 9, name: 'Jabari Parker', video: 'https://vimeo.com/244373722', image: 'jeddeiah-thunell/jabari.jpg' },
  ],
  "Marcus Perry": [
    { id: 1, name: 'THE TALE OF TIMMY BATERMAN', video: 'https://vimeo.com/300544310', image: 'marcus-perry/the-tale.jpg' },
    { id: 2, name: 'Jurassic Doritos', video: 'https://vimeo.com/276166236', image: 'marcus-perry/jurassic.jpg' },
    { id: 3, name: 'A QUIET PLACE “Quiet Theater”', video: 'https://vimeo.com/258518279', image: 'marcus-perry/a-quiet.jpg' },
    { id: 4, name: 'THE RECRUIT', video: 'https://vimeo.com/196675564', image: 'marcus-perry/the-recruit.jpg' },
    { id: 5, name: 'Elton John: My Version', video: 'https://vimeo.com/352567409', image: 'marcus-perry/elton.jpg' },
    { id: 6, name: 'Built To Be Heroes', video: 'https://vimeo.com/305070883', image: 'marcus-perry/built.jpg' },
    { id: 7, name: 'Procession', video: 'https://vimeo.com/316918554', image: 'marcus-perry/procession.jpg' },
    { id: 8, name: 'for King & Country “Ceasefire”', video: 'https://vimeo.com/174049760', image: 'marcus-perry/for-king.jpg' }
  ],
  "Mariana Zdravca": [
    { id: 1, name: 'Chevrolet - GM TRACKER', video: 'https://vimeo.com/407234167', image: 'GM+TRACKER.JPG' },
    { id: 2, name: 'MITSUBISHI ADEUS', video: 'https://vimeo.com/554769796', image: 'mariana-zdravca/mitsubishi.jpg' },
    { id: 3, name: 'PROJOTA A VOZ E O VIOLÃO', video: 'https://vimeo.com/376167021', image: 'mariana-zdravca/projecta.jpg' },
    { id: 4, name: 'Banco 24horas', video: 'https://vimeo.com/594631623', image: 'mariana-zdravca/banco.jpg' },
    { id: 5, name: 'Chevrolet - GM TRAILBLAZER', video: 'https://vimeo.com/376172844', image: 'mariana-zdravca/gm.jpg' },
    { id: 6, name: 'PROJOTA SALMO 23', video: 'https://vimeo.com/406237640', image: 'mariana-zdravca/projota.jpg' },
    { id: 7, name: 'BARRO FICAMOS ASSIM', video: 'https://vimeo.com/376170944', image: 'mariana-zdravca/barrio.jpg' },
    { id: 8, name: 'Chevrloet - TRAILBLAZER SE', video: 'https://vimeo.com/376194059', image: 'mariana-zdravca/trailbazer.jpg' },
    { id: 9, name: 'VALE DAS SOMBRAS', video: 'https://vimeo.com/494502461', image: 'mariana-zdravca/vale.jpg' },
  ],
  "Chris Bristow": [
    { id: 1, name: 'Draper - Men & Machines', video: 'https://vimeo.com/66149381', image: 'chris-bristow/draper.jpg' },
    { id: 2, name: 'Dirty Alice - Power Crown', video: 'https://vimeo.com/45960196', image: 'chris-bristow/dirty.jpg' },
    { id: 3, name: 'Three Mobile Holiday Spam', video: 'https://vimeo.com/367594219', image: 'chris-bristow/three.jpg' },
    { id: 4, name: 'IKEA - The Joy of Storage', video: 'https://vimeo.com/367266639', image: 'chris-bristow/ikea.jpg' },
    { id: 5, name: 'Zen Egg', video: 'https://vimeo.com/367227654', image: 'chris-bristow/zen.jpg' },
    { id: 6, name: 'Subway - Feel Double Good', video: 'https://vimeo.com/367221854', image: 'chris-bristow/subway.jpg' },
  ],
  "Martin Jalfen": [
    { id: 1, name: 'WILKINSON ¨The Moment Before The Moment¨', video: 'https://vimeo.com/384970746', image: 'martin-jalfen/wilkinson.jpg' },
    { id: 2, name: 'Free Mobile ¨Merci Free¨', video: 'https://vimeo.com/519887371', image: 'martin-jalfen/free-mobile.jpg' },
    { id: 3, name: 'FLEX', video: 'https://vimeo.com/510706744', image: 'martin-jalfen/flex.jpg' },
    { id: 4, name: 'Coppel ¨Goodwill´', video: 'https://vimeo.com/493480608', image: 'martin-jalfen/copper.jpg' },
    { id: 5, name: 'Libero Football Magazine ¨Feet & Hands¨', video: 'https://vimeo.com/429752088', image: 'martin-jalfen/libero.jpg' },
    { id: 6, name: 'Santander Bank ¨UEFA CHAMPIONS LEAGUE¨', video: 'https://vimeo.com/430859744', image: 'martin-jalfen/santander.jpg' },
    { id: 7, name: 'Samsung ¨Copilot', video: 'https://vimeo.com/250445454', image: 'martin-jalfen/samsung.jpg' },
    { id: 8, name: 'KFC & Uber Eats ¨Delivery¨', video: 'https://vimeo.com/427411906', image: 'martin-jalfen/kfc.jpg' },
  ],
  "Marcello Lima": [
    { id: 1, name: 'Jeep | Leaders (DC)', video: 'https://vimeo.com/281887970', image: 'Jeep+Leader+DC.JPG' },
    { id: 2, name: 'PEUGEOT | Swimmer', video: 'https://vimeo.com/195614854', image: 'PEUGEOT+Swimmer.JPG' },
    { id: 3, name: 'CNA - Together', video: 'https://vimeo.com/503547696', image: 'CNA+Together.JPG' },
    { id: 4, name: 'SNICKERS | Confused Fan | Director’s Cut (ENG)', video: 'https://vimeo.com/262299395', image: 'marcello-lima/snickers.jpg' },
    { id: 5, name: 'Neosaldina | Sem Cabeça DC', video: 'https://vimeo.com/577190592', image: 'marcello-lima/neosaldina.jpg' },
    { id: 6, name: 'VW AMAROK V6 | VICTORY (DC)', video: 'https://vimeo.com/336214724', image: 'marcello-lima/vw.jpg' },
    { id: 7, name: 'Peugeot - Diving Board', video: 'https://vimeo.com/195614671', image: 'PEUGEOT+Diving+Board.JPG' },
    { id: 8, name: 'Hyundai i30 ‘Inspired by Nature’ ', video: 'https://vimeo.com/139510937', image: 'Inspired+by+Nature.JPG' },
     { id: 9, name: 'CLARO + Facebook | Anitta', video: 'https://vimeo.com/594631120', image: 'marcello-lima/claro-facebook.jpg' },
     { id: 104, name: 'VW | FUNERAL 2017', video: 'https://vimeo.com/221317499', image: 'marcello-lima/vw-funeral.jpg' },

    //{ id: 7, name: 'PEUGEOT | DIVING BOARD | ENGLISH', video: 'https://vimeo.com/195614671', image: 'marcello-lima/peugot.jpg' },

    // { id: 6, name: 'ELMA CHIPS', video: 'https://vimeo.com/537059048', image: 'marcello-lima/elma.jpg' },
    // { id: 8, name: 'MC DONALDS - CHEFS 45’', video: 'https://vimeo.com/266557172', image: 'marcello-lima/mcdonalds.jpg' },
  ],
  "David Villabona": [
    { id: 1, name: 'CNT', video: 'https://vimeo.com/467936937', image: 'dv-1.jpg' },
    { id: 2, name: 'Tigo – Mujer', video: 'https://vimeo.com/467932473', image: 'dv-2.png' },
    { id: 3, name: 'Subway', video: 'https://vimeo.com/228144584', image: 'dv-3.png' },
    { id: 4, name: 'Movistar', video: 'https://vimeo.com/467935457', image: 'dv-4.png' },
    { id: 5, name: 'COLNATUR ', video: 'https://vimeo.com/354099825', image: 'dv-5.png' },
    { id: 6, name: 'Saltin Noel', video: 'https://vimeo.com/400352796', image: 'dv-6.png' },
    { id: 7, name: 'Día del Padre – Director’s cut', video: 'https://vimeo.com/430206006', image: 'dv-7.png' },
    { id: 8, name: 'Svelty Nestlé', video: 'https://vimeo.com/367390851', image: 'dv-8.png' }
  ],
  "Diego Fried": [
    { id: 1, name: 'Brahma - La Envidia Sana', video: 'https://vimeo.com/739040598', image: 'brahma envidia sana.jpg' },
    { id: 2, name: 'Alfa', video: 'https://vimeo.com/571894387', image: 'df-9.jpg' },
    { id: 3, name: 'Sube', video: 'https://vimeo.com/571893654', image: 'df-10.jpg' },
    { id: 4, name: 'Aquí se juega, aquí lo vives', video: 'https://vimeo.com/274180054', image: 'df-1.png' },
    { id: 5, name: 'Challenge ', video: 'https://vimeo.com/427253030', image: 'df-3.png' },
    { id: 6, name: 'Casancrem - Sunday ', video: 'https://vimeo.com/513687572', image: 'diego-fried/casancrem.jpg' },
    { id: 7, name: 'Vitality - We All Can ', video: 'https://vimeo.com/513680388', image: 'diego-fried/vitality.jpg' },
    { id: 8, name: 'Paseo La Galeria - Discover (Director’s Cut)', video: 'https://vimeo.com/623421667', image: 'diego-fried/paseo-la-galeria.jpg' },
    { id: 9, name: 'La Fiesta Silenciosa – Trailer', video: 'https://vimeo.com/423875846', image: 'diego-fried/la-fiesta-silenciosa.jpg' },
    { id: 10, name: 'KFC + Criollismo - Criollada', video: 'https://vimeo.com/643122983', image: '%2B+Criollismo+-+Criollada.JPG' }
   //  { id: 4, name: 'Sura Insurance', video: 'https://vimeo.com/263550837', image: 'df-2.png' },
   //  { id: 6, name: 'Song swimmer', video: 'https://vimeo.com/427596057', image: 'df-4.png' },
   //  { id: 7, name: 'Love Story – Director’s cut ', video: 'https://vimeo.com/427250887', image: 'df-5.png' },
   //  { id: 8, name: 'Answer', video: 'https://vimeo.com/259940986', image: 'df-6.png' }
  ],
  "Hernán Bargman": [
    { id: 1, name: 'KFC CoroNoel', video: 'https://vimeo.com/showcase/8201803/video/386218760', image: 'hb-1.jpg' },
    { id: 2, name: 'Movistar - Partidazo', video: 'https://vimeo.com/showcase/8201803/video/366770434', image: 'hb-2.jpg' },
    { id: 3, name: 'Saladix - Bacon', video: 'https://vimeo.com/showcase/8201803/video/513477344', image: 'hb-3.jpg' },
    { id: 4, name: 'Chevrolet Onix', video: 'https://vimeo.com/showcase/8201803/video/415686298', image: 'hb-4.jpg' },
    { id: 5, name: 'KFC Mamá', video: 'https://vimeo.com/showcase/8201803/video/368252318', image: 'hb-5.jpg' },
    { id: 6, name: 'Eurojackpot', video: 'https://vimeo.com/showcase/8201803/video/368252014', image: 'hb-6.jpg' },
    { id: 7, name: 'Ribeiro - Pareja', video: 'https://vimeo.com/showcase/8201803/video/366775514', image: 'hb-7.jpg' },
    { id: 8, name: 'HONDA WR-V ESCAPISTAS', video: 'https://vimeo.com/showcase/8201803/video/366770451', image: 'hb-8.jpg' }
  ],
  "Javier Zea": [
    { id: 1, name: 'Lanzamiento mood tendencias', video: 'https://vimeo.com/392743038', image: 'jz-1.png' },
    { id: 2, name: 'La zapatera', video: 'https://vimeo.com/377439351', image: 'jz-2.png' },
    { id: 3, name: 'Marquis / María Gracia Gamarra', video: 'https://vimeo.com/469008946', image: 'jz-3.png' },
    { id: 4, name: 'Index / Mafer y Hugo', video: 'https://vimeo.com/470288387', image: 'jz-4.png' },
    { id: 5, name: 'Primavera verano 19 ', video: 'https://vimeo.com/295678499', image: 'jz-5.png' },
    { id: 6, name: 'Mazda - #yomequedoencasa', video: 'https://vimeo.com/403102260', image: 'jz-6.png' }
  ],
  "Jeff Carrera": [
    { id: 1, name: 'Día de la madre', video: 'https://vimeo.com/413830145', image: 'Dia+de+la+Madre+(sin+barras+negras).JPG' },
    { id: 2, name: 'Liga Pro Gaming', video: 'https://vimeo.com/393206967', image: 'jc-2.png' },
    { id: 3, name: 'Cosas que no cambian ', video: 'https://vimeo.com/304686581', image: 'jc-5.png' },
    { id: 4, name: 'Vamos pa’ lante', video: 'https://vimeo.com/305159438', image: 'Vamos+pa+Lante+(sin+barras+negras).JPG' },
    { id: 5, name: '40 Aniversario / Caja Piura', video: 'o	https://vimeo.com/677905026', image: 'jeff-carrera/caja-piura.jpg' },
    { id: 6, name: 'Real Plaza - Estamos aquí para cuidarte', video: 'https://vimeo.com/305159438', image: 'jeff-carrera/real-plaza.jpg' },
    { id: 7, name: 'Mia Mont - Lero Lero Director’s Cut', video: 'https://vimeo.com/350158101', image: 'jeff-carrera/mia-mont.jpg' },
    { id: 8, name: 'Tourista ft. Mia Mont ft. Young Eiby - Te vi llorando Director’s Cut', video: 'https://vimeo.com/394083376', image: 'jeff-carrera/tourista.jpg' }
   //  { id: 3, name: 'Futbol', video: 'https://vimeo.com/350029442', image: 'jc-3.png' },
   //  { id: 4, name: 'Naciste para ser feliz', video: 'https://vimeo.com/323353481', image: 'jc-4.png' },
   //  { id: 6, name: 'Max Internacional', video: 'https://vimeo.com/304045311', image: 'jc-6.png' },
   //  { id: 8, name: 'Solita remix', video: 'https://vimeo.com/281089485', image: 'jc-8.png' }
  ],
  "Sebas Sánchez": [
    { id: 1, name: 'Mujeriegas', video: 'https://vimeo.com/158781632', image: 'ss-1.png' },
    { id: 2, name: 'Elige todo', video: 'https://vimeo.com/173844689', image: 'ss-2.png' },
    { id: 3, name: 'Undisputed', video: 'https://vimeo.com/158810276', image: 'ss-3.png' },
    { id: 4, name: 'Bankennials', video: 'https://vimeo.com/241713536', image: 'ss-4.png' },
    { id: 5, name: 'The Great Collins', video: 'https://vimeo.com/158747588', image: 'ss-5.png' },
    { id: 6, name: 'The One Nearby', video: 'https://vimeo.com/189989279', image: 'ss-6.png' },
    { id: 7, name: 'Fast Film', video: 'https://vimeo.com/164560294', image: 'ss-7.png' },
    { id: 8, name: 'Falcon', video: 'https://vimeo.com/226925451', image: 'ss-8.png' }
  ],
  "Jorge Ponce Betti": [
   { id: 1, name: "M.A.R.T.A. USB", video: "https://vimeo.com/260928708", image: "jpb-1.png" },
   { id: 2, name: "Independence", video: "https://vimeo.com/169712085", image: "jpb-2.png" },
   { id: 3, name: "La lengua del sabor", video: "https://vimeo.com/244088666", image: "jpb-3.png" },
   { id: 4, name: "Movistar – Director’s cut", video: "https://vimeo.com/196335747", image: "jpb-4.png" },
   { id: 5, name: "Hamlet", video: "https://vimeo.com/188921425", image: "jpb-5.png" },
   { id: 6, name: "Sperm Collection", video: "https://vimeo.com/135677309", image: "jpb-6.png" },
   { id: 7, name: "Chess", video: "https://vimeo.com/135677331", image: "jpb-7.png" },
   { id: 8, name: "Netflix Campaign", video: "https://vimeo.com/243033242", image: "jpb-8.png" }
 ],
 "Adri Laham": [
   { id: 1, name: "Fibertel", video: "https://vimeo.com/354273375", image: "al-1.png" },
   { id: 2, name: "Amigos", video: "https://vimeo.com/109058446", image: "al-2.png" },
   { id: 3, name: "Perfect girl", video: "https://vimeo.com/169865147", image: "al-3.png" },
   { id: 4, name: "Nena", video: "https://vimeo.com/200821959", image: "al-4.png" },
   { id: 5, name: "Babysec", video: "https://vimeo.com/374712262", image: "al-5.png" },
   { id: 6, name: "Save Water", video: "https://vimeo.com/174698560", image: "al-6.png" }
 ],
 "Chris Bistrow": [
  //  { id: 1, name: "Bedtime Stories", video: "https://vimeo.com/367027465", image: "cb-1.png" },
  //  { id: 2, name: "Social films", video: "https://vimeo.com/367027070", image: "cb-2.png" },
  //  { id: 1, name: "Feel Double Good", video: "https://vimeo.com/367221854", image: "cb-3.png" },
   { id: 1, name: "The Joy of Storage", video: "https://vimeo.com/367266639", image: "cb-4.png" },
   { id: 2, name: "Thrre mobile", video: "https://vimeo.com/367594219", image: "cb-5.png" },
   { id: 3, name: "The data song", video: "https://vimeo.com/367028513", image: "cb-6.png" },
   { id: 4, name: "Wuntu", video: "https://vimeo.com/367215669", image: "cb-7.png" },
   { id: 5, name: "Hit of the strong stuff", video: "https://vimeo.com/367029854", image: "cb-8.png" },
   { id: 6, name: "Airwaves - Hit of the Strong Stuff", video: "https://vimeo.com/367029854", image: "Airwaves.JPG" },
   { id: 7, name: "IKEA - Bedtime Stories", video: "https://vimeo.com/367027465", image: "IKEA+-+bedtime+stories.JPG" },
   { id: 8, name: "Barclays - The Data Song", video: "https://vimeo.com/367028513", image: "Barclays+-+the+data+song.JPG" },
   { id: 9, name: "Subway - Feel Double Good", video: "https://vimeo.com/367221854", image: "SUBWAY+FEEL+DOUBLE+GOOD.JPG" },
   { id: 10, name: "The Pharmaceutical Guild of Australia", video: "https://vimeo.com/367028291", image: "the+pharmecetical+guild+of+Australia.JPG" },
 ],
 "Jonathan Bregel": [
   { id: 1, name: "Back to School, Forward with Confidence", video: "https://vimeo.com/270784369", image: "jb-1.png" },
   { id: 2, name: "This is called life", video: "https://vimeo.com/171118697", image: "jb-2.png" },
   { id: 3, name: "What Drives You", video: "https://vimeo.com/270784201", image: "jb-3.png" },
   { id: 4, name: "4 de Julio", video: "https://vimeo.com/171118712", image: "jb-4.png" },
   { id: 5, name: "Home", video: "https://vimeo.com/298018040", image: "jb-5.png" },
   { id: 6, name: "Killing Kennedy", video: "https://vimeo.com/270784277", image: "jb-6.png" },
   { id: 7, name: "Holi", video: "https://vimeo.com/171118412", image: "jb-7.png" },
   { id: 8, name: "Get old", video: "https://vimeo.com/171118650", image: "jb-8.png" }
 ],
 "Matt Bieler": [
   { id: 1, name: "Special K", video: "https://vimeo.com/491495332", image: "mb-1.png" },
   { id: 2, name: 'Modelo “SmokeJumper Jon Hernandez” DC', video: 'https://vimeo.com/409903379', image: 'matt-bieler/modelo-smoker.jpg' },
   { id: 3, name: 'Modelo “Brian Ortega”', video: 'https://vimeo.com/340035448', image: 'Modelo+BRIAN+ORTEGA.JPG' },
   { id: 4, name: 'Apple “Cherie”', video: 'https://vimeo.com/265682738', image: 'matt-bieler/apple.jpg' },
   { id: 5, name: 'VISIBLE', video: 'https://vimeo.com/474871704', image: 'matt-bieler/visible.jpg' },
   { id: 6, name: 'Three “unlimited”', video: 'https://vimeo.com/519779456', image: 'matt-bieler/three.jpg' },
   { id: 7, name: "The Lioness", video: "https://vimeo.com/418505965", image: "mb-2.png" },
   { id: 8, name: 'Synchrony “Working Forward”', video: 'https://vimeo.com/164189600', image: 'Synchrony+Working+Forward.JPG' },
   { id: 9, name: "Three Ingredients", video: "https://vimeo.com/164636922", image: "mb-8.png" },
  // { id: 3, name: "Cherie", video: "https://vimeo.com/265682738", image: "mb-3.png" },
  // { id: 4, name: "Magic Moments", video: "https://vimeo.com/148957879", image: "mb-4.png" },
   //{ id: 5, name: "Drogba’s Journey", video: "https://vimeo.com/87481894", image: "mb-5.png" },
 //  { id: 6, name: "Steph Curry", video: "https://vimeo.com/198527565", image: "mb-6.png" },
  // { id: 7, name: "Fighting Spirit", video: "https://vimeo.com/239177279", image: "mb-7.png" },

  // { id: 9, name: 'Modelo “Damian Lillard” DC', video: 'https://vimeo.com/641252764', image: 'matt-bieler/modelo-damian.jpg' },
  // { id: 10, name: 'Coca-Cola Olympics “Uplift” DC', video: 'https://vimeo.com/582143881', image: 'matt-bieler/coca-cola.jpg' },
  // { id: 11, name: 'Modelo - Mister Cartoon DC', video: 'https://vimeo.com/527971809', image: 'matt-bieler/modelo-mister.jpg' },
 ],
 "Mccoy | Meyer": [
   { id: 1, name: "Business Bear", video: "https://vimeo.com/302721604", image: "mm-1.png" },
   { id: 2, name: "Bowling Basketball", video: "https://vimeo.com/151705371", image: "mm-2.png" },
   { id: 3, name: "Boudreaux’s", video: "https://vimeo.com/249878416", image: "mm-3.png" },
   { id: 4, name: "Country Archer", video: "https://vimeo.com/274934291", image: "mm-4.png" },
   { id: 5, name: "WWE", video: "https://vimeo.com/470601849", image: "mm-5.png" },
   { id: 6, name: "Holiday Warrior", video: "https://vimeo.com/378715449", image: "mm-6.png" },
   { id: 7, name: "Floss", video: "https://vimeo.com/372007418", image: "mm-7.png" },
   { id: 8, name: "Hunter Hayes", video: "https://vimeo.com/306842671", image: "mm-8.png" }
 ],
 "Michael Parenteau": [
   { id: 1, name: "A New World Of Magic | DISNEY VACATION CLUB", video: "https://vimeo.com/699243827", image: "DISNEY+VACATION.JPG" },
   { id: 2, name: "Break Up | CLMBR", video: "https://vimeo.com/677311939", image: "Break+Up+CLMBER.JPG" },
   { id: 3, name: "Have You Ever | THE NORTH FACE", video: "https://vimeo.com/556159767", image: "Have+You+Ever+THE+NORTH+FACE.JPG" },
   { id: 4, name: "ByBorre | SAMSUNG", video: "https://vimeo.com/380333614", image: "michael-parenteau/by-borre.jpg" },

   { id: 5, name: "Limitless | KIRLOSKAR", video: "https://vimeo.com/500118590", image: "michael-parenteau/limitless.jpg" },
   { id: 6, name: "Fill Your Days | Ford", video: "https://vimeo.com/298608015", image: "michael-parenteau/fill-your-days.jpg" },
   { id: 7, name: "Wish you were here | ZAGALETA TOURISM", video: "https://vimeo.com/282228180", image: "michael-parenteau/wish-you-were-here.jpg" },
   { id: 8, name: "A Moment In Time | MERCEDES AMG", video: "https://vimeo.com/349066789", image: "michael-parenteau/a-moment-in-time.jpg" },
   { id: 9, name: "A View From Below | CHACO FOOTWEAR", video: "https://vimeo.com/269986482", image: "michael-parenteau/a-view.jpg" },
   { id: 10, name: "Get Off The Couch | ONEWHEEL", video: "https://vimeo.com/474849966", image: "michael-parenteau/get-off.jpg" },

   
   // { id: 5, name: "Between chaos and peace", video: "https://vimeo.com/425555315", image: "mp-5.png" },
   // { id: 6, name: "Powered by you", video: "https://vimeo.com/218173309", image: "mp-6.png" },
   // { id: 7, name: "Where it all begins", video: "https://vimeo.com/361155070", image: "mp-7.png" },
   // { id: 8, name: "They just don’t know it yet", video: "https://vimeo.com/271186608", image: "mp-8.png" }
 ],
 "Tim Abshire": [
   { id: 1, name: "Sasquatch", video: "https://vimeo.com/201312715", image: "ta-1.png" },
   { id: 2, name: "Sniffin", video: "https://vimeo.com/362399271", image: "ta-2.png" },
   { id: 3, name: "Just Crack An Egg", video: "https://vimeo.com/289002831", image: "ta-3.png" },
   { id: 4, name: "Jamaica", video: "https://vimeo.com/205321462", image: "ta-4.png" },
   { id: 5, name: "Febreze", video: "https://vimeo.com/105299247", image: "ta-5.png" },
   { id: 6, name: "Raisin Bran with Bananas", video: "https://vimeo.com/290306848", image: "ta-6.png" },
   { id: 7, name: "Drew Barrymore – Flirting", video: "https://vimeo.com/291208584", image: "ta-7.png" },
   { id: 8, name: "Red Alert", video: "https://vimeo.com/65653428", image: "ta-8.png" }
 ],
 "Ben Tricklebank": [
   { id: 1, name: "Hennessy V.S.O.P: Harmony. Mastered From Chaos - Directors Cut", video: "https://vimeo.com/173127865", image: "bt-1.png" },
   { id: 2, name: "Lenovo - Rise of Smarter", video: "https://vimeo.com/359142325", image: "bt-2.png" },
   { id: 3, name: "Glenfiddich - Unlearn Whisky [Directors Cut]", video: "https://vimeo.com/189078123", image: "bt-3.png" },
   { id: 4, name: "Sony Bravia - Director’s Cut", video: "https://vimeo.com/142122468", image: "ben-tricklebank/sony.jpg" },
   { id: 5, name: "Audible - Coffee [Director’s Cut]", video: "https://vimeo.com/225518323", image: "ben-tricklebank/audible.jpg" },
   { id: 6, name: "Chase Sapphire", video: "https://vimeo.com/478199913", image: "bt-7.png" },
   { id: 7, name: "Cupra Ateca", video: "https://vimeo.com/478200750", image: "bt-6.png" },
   { id: 8, name: "Delta x LA2028", video: "https://vimeo.com/402736043", image: "bt-5.png" },
  //  { id: 4, name: "Samsung 8K QLED – Director’s cut", video: "https://vimeo.com/342377187", image: "bt-4.png" },
  //  { id: 8, name: "BMX ¡X", video: "https://vimeo.com/504667488", image: "bt-8.png" },
  //  { id: 9, name: "Light Echoes", video: "https://vimeo.com/74013667", image: "ben-tricklebank/light.jpg" },
  //  { id: 11, name: "Ørsted - Home", video: "https://vimeo.com/369706852", image: "ben-tricklebank/orsted.jpg" },
  //  { id: 13, name: "The Order 1886 - Full Length", video: "https://vimeo.com/118675392", image: "ben-tricklebank/the-order.jpg" },
 ],
 "Ross Allen": [
   { id: 1, name: "Heartbeat of the Nation", video: "https://vimeo.com/407745497", image: "ra-1.png" },
   { id: 2, name: "Shaw “Off/On”", video: "https://vimeo.com/330135457", image: "ra-2.png" },
   { id: 3, name: "Intelligent Motion", video: "https://vimeo.com/205675068", image: "ra-3.png" },
   { id: 4, name: "What Happens When – Director’s cut", video: "https://vimeo.com/358827524", image: "ra-4.png" },
   { id: 5, name: "Your Nature, By Nature", video: "https://vimeo.com/296346996", image: "ra-5.png" },
   { id: 6, name: "Canada Day Film", video: "https://vimeo.com/284977928", image: "ra-6.png" },
   { id: 7, name: "Bad", video: "https://vimeo.com/466626224", image: "ra-7.png" },
   { id: 8, name: "SoulCycle", video: "https://vimeo.com/294495305", image: "ra-8.png" }
 ],
 "Pollo Arce": [
   { id: 1, name: "Amazon Prime Vimeo : El Presidente", video: "https://vimeo.com/510454414", image: "pa-1.png" },
   { id: 2, name: "Colmillos", video: "https://vimeo.com/480513784", image: "pa-2.png" },
   { id: 3, name: "Formal Sport", video: "https://vimeo.com/480448585", image: "pa-3.png" },
   { id: 4, name: "Fooding", video: "https://vimeo.com/480439327", image: "pa-4.png" },
   { id: 5, name: "Closer is better", video: "https://vimeo.com/480434904", image: "pa-5.png" },
   { id: 6, name: "Pelota", video: "https://vimeo.com/480430304", image: "pa-6.png" },
   { id: 7, name: "Umbrella", video: "https://vimeo.com/480421035", image: "pa-7.png" },
   { id: 8, name: "INDIO Barrios: México", video: "https://vimeo.com/480407149", image: "pa-8.png" },
   { id: 9, name: 'DIRECTV Acción', video: 'https://vimeo.com/568557687', image: 'pollo-arce/directv.jpg' },
   { id: 10, name: 'Hogares Unión – Ruido', video: 'https://vimeo.com/528370203', image: 'pollo-arce/hogares.jpg' },
   { id: 11, name: 'Caliente : NFL', video: 'https://vimeo.com/528361816', image: 'pollo-arce/caliente.jpg' },
   { id: 12, name: 'AT&T : Inbox GOT', video: 'https://vimeo.com/528358540', image: 'pollo-arce/at&t.jpg' },
   { id: 13, name: 'INDIO Barrios : Los Angeles', video: 'https://vimeo.com/480450171', image: 'pollo-arce/indio.jpg' },
   { id: 14, name: 'Coca Cola : Fooding', video: 'https://vimeo.com/480439327', image: 'pollo-arce/coca-cola.jpg' },
   { id: 15, name: 'KNORR : Umbrella', video: 'https://vimeo.com/480421035', image: 'pollo-arce/knorr.jpg' }
 ],
 "Juan Diego Servat":[
  { id: 1, name: 'UTEC - Metaverso UTEC', video: 'https://vimeo.com/731528173', image: 'UTEC.jpg'},
  { id: 2, name: 'Wong Navidad - Osito', video: 'https://vimeo.com/296791940', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795464/Wong_OSITO_ur6xv6.jpg', other:true },
  { id: 3, name: 'Campo Fe', video: 'https://vimeo.com/701090018', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795463/Campo_Fe_qzctpp.jpg', other:true },
  { id: 4, name: 'Salmitas', video: 'https://vimeo.com/296791940', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795464/SALMITAS_aiymn0.jpg', other:true },
  { id: 5, name: 'Oechsle - día del padre', video: 'https://vimeo.com/701090400', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795463/Oechsle_PAPA_p5iitc.jpg', other:true },
  { id: 6, name: 'Movistar Pre Plan - Jelys', video: 'https://vimeo.com/304943379', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795463/Movistar_Jellys_itlguk.jpg', other:true },
  { id: 7, name: 'Wong Navidad - Vino', video: 'https://vimeo.com/296792357', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795464/Wong_VINO_jrfumc.jpg', other:true },
  { id: 8, name: 'Autopia', video: 'https://vimeo.com/701091688', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795463/Autopia_fedral.jpg', other:true },
  { id: 9, name: 'Acción Contra el Hambre - Plato Solidario', video: 'https://vimeo.com/295630659', image: 'https://res.cloudinary.com/hmt-s-a-c/image/upload/v1652795463/ACCH_wttwpa.jpg', other:true },
 ],
 "Miguel Vila" : [
  { id: 1, name: 'Postobon', video: 'https://vimeo.com/371485830', image: 'POSTOBON+Tomate+la+Vida.JPG' },
  { id: 2, name: 'Hit', video: 'https://vimeo.com/297226772', image: 'miguel-villa/Hit.jpg' },
  { id: 3, name: 'Pepsi Black', video: 'https://vimeo.com/253617117', image: 'miguel-villa/pepsi-black.jpg' },
  { id: 4, name: 'GATORADE FIERCE COLOMBIA', video: 'https://vimeo.com/302839269', image: 'miguel-villa/GATORADE-FIERCE-COLOMBIA.jpg' },
  { id: 5, name: 'Movistar Navidad 2021 El Salvador', video: 'https://vimeo.com/665726250', image: 'miguel-villa/movistar-navidad.jpg' },
  { id: 6, name: 'Colombiana Yatra', video: 'https://vimeo.com/334916331', image: 'miguel-villa/colombiana-yatra.jpg' },
  { id: 7, name: 'PEPSI WEB', video: 'https://vimeo.com/141222299', image: 'miguel-villa/pepsi-web.jpg' },
  { id: 8, name: 'Ringo Vitality', video: 'https://vimeo.com/678516108', image: 'miguel-villa/ringo.jpg' },
 ],
 "Felipe Rueda":[
  { id: 1, name: 'Club Colombia - Siembra', video: 'https://vimeo.com/479068314', image: 'CLUB+COLOMBIA+Siembra.JPG' },
  { id: 2, name: 'Fedepalma - Único como nuestra tierra', video: 'https://vimeo.com/482632351', image: 'Fedepalma.JPG' },
  { id: 3, name: 'Dove - Mi belleza, mi decisión', video: 'https://vimeo.com/374517938', image: 'Dove+-+Mi+Belleza.JPG' },
  { id: 4, name: 'City Tv - Tu mundo aquí', video: 'https://vimeo.com/247859841', image: 'City+TV+-+Tu+Mundo+Aqui.JPG' },
  { id: 5, name: 'Mawie - Linea gourmet capilar', video: 'https://vimeo.com/359536511', image: 'Mawie+-+linea+gourmet+capilar.JPG' },
  { id: 6, name: 'Huawei - P8', video: 'https://vimeo.com/247867173', image: 'Huawei+P8.JPG' },
  { id: 7, name: 'Juan Valdez - Navidad', video: 'https://vimeo.com/378635900', image: 'Juan+Valdez+-+Navidad.JPG' },
  { id: 8, name: 'Amelissa - Maluma', video: 'https://vimeo.com/247855616', image: 'Amelissa+-+Maluma.JPG' },
 ],
 "Rafa Martínez":[
  { id: 1, name: 'AeroMexico - A World Without Borders', video: 'https://vimeo.com/283104646', image: 'AeroMexico.JPG' },
  { id: 2, name: 'Avianca - Boeing 787', video: 'https://vimeo.com/118300730', image: 'Avianca+Boeing+787.JPG' },
  { id: 3, name: 'Tigo - Datos', video: 'https://vimeo.com/119574409', image: 'Tigo+-+Datos.JPG' },
  { id: 4, name: 'El Piedra - Trailer', video: 'https://vimeo.com/306296529', image: 'El+Piedra+-+Trailer.JPG' },
  { id: 5, name: 'Milo - Deportes', video: 'https://vimeo.com/172075321', image: 'Milo+-+Deportes.JPG' },
  { id: 6, name: 'Ritmo Salvaje - Tráiler oficial', video: 'https://vimeo.com/677883178', image: 'Ritmo+Salvaje.JPG' },
  { id: 7, name: 'BCS #CuenteConmigo', video: 'https://vimeo.com/294879873', image: 'BCS.JPG' },
  { id: 8, name: 'PROSPERIDAD SOCIAL - Alas', video: 'https://vimeo.com/220719453', image: 'PROSPERIDAD+SOCIAL+-+Alas.JPG' },
 ],
 "Rafaela Carvalho":[
  { id: 1, name: 'Mitsubishi | Romaria', video: 'https://vimeo.com/238645937', image: 'Mitsubishi+Romaria.JPG' },
  { id: 2, name: 'Tam | Dream in the sky', video: 'https://vimeo.com/187233022', image: 'Tam+Dream+in+the+Sky.JPG' },
  { id: 3, name: 'Sulamérica | 120 years old', video: 'https://vimeo.com/187232838', image: 'Sulamerica+120+years+old.JPG' },
  { id: 4, name: 'Personal | A life with you', video: 'https://vimeo.com/331790507', image: 'Personal+-+A+life+with+you.JPG' },
  { id: 5, name: 'WHATSAPP | POSITIVE OR NEGATIVE?', video: 'https://vimeo.com/590059658', image: '5+whatsapp.JPG' },
  { id: 6, name: 'Sesc | Dance', video: 'https://vimeo.com/187234258', image: '2+Sesc.JPG' },
  { id: 7, name: 'Mizuno | Uphill', video: 'https://vimeo.com/186012072', image: 'Mizuno+-+Uphill.JPG' },
  { id: 8, name: 'NETFLIX | PRIDE', video: 'https://vimeo.com/435965846', image: 'Netflix+-+Pride.JPG' },
 ],
 "Sophia Banks":[
  { id: 1, name: 'Target Natural Forces', video: 'https://vimeo.com/352093476', image: 'Naturla+Forces.JPG' },
  { id: 2, name: 'Kendall + Kylie Jenner Full Fall Campaign', video: 'https://vimeo.com/290588245', image: 'Fall+Campaign.JPG' },
  { id: 3, name: 'Range Rover: Go All The Way', video: ' https://vimeo.com/386355657', image: 'Range+Rover+-+Go+All+the+Way.JPG' },
  { id: 4, name: 'VRC: The 2019 Melbourne Cup', video: 'https://vimeo.com/365100103', image: 'VRC.JPG' },
  { id: 5, name: 'Girls Skate for Christian Siriano', video: 'https://vimeo.com/158386221', image: 'Girls+Skate.JPG' },
  { id: 6, name: 'Chobani - Fighting for Happily Ever After', video: 'https://vimeo.com/245287335', image: 'Chobani+Fighting.JPG' },
  { id: 7, name: 'Black Site - Official Trailer', video: 'https://vimeo.com/699051275 ', image: 'Black+Site.JPG' },
  { id: 8, name: 'Proxy - Trailer', video: 'https://vimeo.com/455635999', image: 'Proxy+-+Trailer.JPG' },
],
"Hector Orbegoso":[
  { id: 1, name: 'BBVA - Bancomer “Distraída”', video: 'https://vimeo.com/319336967', image: 'BBVA+Distraida.JPG' },
  { id: 2, name: 'Cabify - Voom', video: 'https://vimeo.com/313214831', image: 'Cabify+VOOM.JPG' },
  { id: 3, name: 'Gatorade - “GGSI Felipao”', video: 'https://vimeo.com/152276153', image: 'Gatorade+GGSI+Felipao.JPG' },
  { id: 4, name: 'Claro “Descomplicate” Pieza 2 “Facturas”', video: 'https://vimeo.com/143267038', image: 'CLARO+PIEZA+2.JPG' },
  { id: 5, name: 'Video Inaugural Suramericano de Baloncesto 2014', video: 'https://vimeo.com/102452519', image: 'Video+Inaugural+Suramericano+de+Baloncesto+2014.JPG' },
  { id: 6, name: 'Video Inauguración III Juegos Suramericanos de Playa Vargas 2014', video: 'https://vimeo.com/96043703', image: 'JUEGOS+2014.JPG' },
  { id: 7, name: 'Fundación BBVA con Iker Casillas', video: 'https://vimeo.com/97268495', image: 'BBVA+Casillas.JPG' },
  { id: 8, name: 'BBVA Bancomer “Pelito”', video: 'https://vimeo.com/319582762', image: 'BBVA+Pelito.JPG' },
],
"Jose Navarro": [
  { id: 1, name: 'Aspem - Migrante', video: 'https://vimeo.com/233515651', image: 'ASPEM - MIGRANTE.JPG' },
  { id: 2, name: 'Banco Falabella - Visa Manos', video: 'https://vimeo.com/239666496', image: 'BF - VISA MANOS.JPG' },
  { id: 3, name: 'Cencosud - Alimentos que Aceleran el Metabolismo', video: 'https://vimeo.com/2337297991', image: 'WhatsApp+Image+2022-06-27+at+7.37.49+PM.jpeg' },
  { id: 4, name: 'Cencosud - Bomberos', video: 'https://vimeo.com/252886865', image: 'CENCOSUD - BOMBEROS.JPG' },
  { id: 5, name: 'Interbank - Interseguro', video: 'https://vimeo.com/233666978', image: 'INTERSEGURO.JPG' },
  { id: 6, name: 'Maestro - Reconstrucción', video: 'https://vimeo.com/233421435', image: 'MEASTRO - RECONSTRUCCION.JPG' },
  { id: 7, name: 'Plaza Vea - Copa América', video: 'https://vimeo.com/356196843', image: 'PLAZA VEA - COPA AMERICA PROMO.JPG' },
  { id: 8, name: 'Sal Marina - Manifiesto', video: 'https://vimeo.com/296756137', image: 'SAL MARINA - MANIFIESTO.JPG' },
],
"Alvaro Stocker": [
  { id: 1, name: 'Nespresso - Masticar', video: 'https://vimeo.com/474869636', image: 'Nespresso - Masticar.jpg' },
  { id: 2, name: 'Mercedes Benz - The Indelible Road', video: 'https://vimeo.com/548610819', image: 'Mercedez Benz - The Indellible Road.jpg' },
  { id: 3, name: 'Kia - Discover', video: 'https://vimeo.com/311145708', image: 'Kia - Discover.jpg' },
  { id: 4, name: 'Duki - A Punta de Espada', video: 'https://vimeo.com/367320042', image: 'Duki - A Punta de Espada.jpg' },
  { id: 5, name: 'Caro Cuore - Body & Soul', video: 'https://vimeo.com/445882980', image: 'Caro Coure - Body & Soul.jpg' },
  { id: 6, name: 'Espn - Do What Inspires You', video: 'https://vimeo.com/230246052', image: 'ESPN - Do What You Love.jpg' },
  //{ id: 7, name: 'Kia - Movement That Inspireso', video: 'https://vimeo.com/296756137', image: 'SAL MARINA - MANIFIESTO.JPG' },
],
"Ivan Vaccaro":[
  { id: 1, name: 'Citroen C4 - Let Comfort Move You', video: 'https://vimeo.com/265259641', image: 'Citroen C4 - Let Comfort Move You.jpg' },
  { id: 2, name: 'Toyota - Toyota Avanza 2022', video: 'https://vimeo.com/715558400', image: 'Toyota Avanza.jpg' },
  { id: 3, name: 'Logitech - Fernando Alonso', video: 'https://vimeo.com/375320455', image: 'Logitech - Fernando Alonso.jpg' },
  { id: 4, name: 'Logitech - Play to Win', video: 'https://vimeo.com/445905257', image: 'Logitech - Play to Win.jpg' },
  { id: 5, name: 'IO - Mabe', video: 'https://vimeo.com/572781369', image: 'IO - MABE.jpg' },
  { id: 6, name: 'Lupin Temp. 2 Feat. Los Simuladores', video: 'https://vimeo.com/563027293', image: 'Lupin Temp 2.jpg' },
  { id: 7, name: 'Toyota Hybrid - Ready For Change', video: 'https://vimeo.com/542700147', image: 'Toyota Hybrid.jpg' },
  { id: 8, name: 'Pepsi - Black', video: 'https://vimeo.com/229587775', image: 'Pepsi Black.jpg' },
]
 
//  "Hector Orbegoso": [
//   { id: 1, name: 'BBVA - Bancomer “Distraída”', video: 'https://vimeo.com/319336967', image: 'POSTOBON+Tomate+la+Vida.JPG' },
//  ]
 }

 