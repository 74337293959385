import React, { useState, useEffect } from "react";
import dataJson from "../utils/translate.json";

const Manifest = () => {
  const [languageSelected, setLanguageSelected] = useState("es");

  const initLanguage = () => {
    const languageStorage = localStorage.getItem("language");
    if (languageStorage) setLanguageSelected(JSON.parse(languageStorage));
  };

  useEffect(() => {
    initLanguage();
  }, []);

  return (
    <div className="manifiesto">
      <div className="manifiesto__wrapper">
        <div className="manifiesto__corner--top-left"></div>
        <div className="manifiesto__corner--top-right"></div>
        <div className="manifiesto__corner--bottom-left"></div>
        <div className="manifiesto__corner--bottom-right"></div>
        <h3 className="title--36 title--36-bold text--justify">
          {dataJson[languageSelected].manifiesto.split("&&&")[0]}
        </h3>
        <h3 className="title--36 title--36-light text--justify">
          {dataJson[languageSelected].manifiesto.split("&&&")[1]}
        </h3>
      </div>
    </div>
  );
};

export default Manifest;
