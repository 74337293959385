import React from "react";
import HomeGrid from "../components/HomeGrid";

const Home = () => {
  return (
    <HomeGrid />
  );
};

export default Home;
